import React from "react";
import Imgix from "react-imgix";
import "lazysizes";
import "lazysizes/plugins/attrchange/ls.attrchange";
import "lazysizes/plugins/blur-up/ls.blur-up";
import { Link } from "gatsby";
import Layout from "../components/Layout";
import styles from "../styles/Error.module.scss";

const Image =
  "https://imgix.cosmicjs.com/3977b0c0-de99-11eb-a12b-17ba28648e41-404Image.png";
const Custom404 = () => (
  <Layout
    noIndex
    image={Image}
    title="404 | Bidddy Page Not Found"
    description="Page Not Found. Please contact us if you require more assistance"
  >
    <div className={styles.imageContainer}>
      <Imgix
        src={`${Image}`}
        sizes="100%"
        imgixParams={{ ar: "1075:723" }}
        htmlAttributes={{
          alt: "Bidddy 101 guide to getting started on Bidddy",
          src: `${Image}?blur=200&px=2&auto=format`,
        }}
        attributeConfig={{
          src: "data-src",
          srcSet: "data-srcset",
          sizes: "data-sizes",
        }}
        className="lazyload blur-up"
      />
      <p>Out surfing. You&apos;ve hit a route that doesn&apos;t exist.</p>
      <Link to="/">Back To Home</Link>
    </div>
  </Layout>
);

export default Custom404;
